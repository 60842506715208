import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginLeft: (props) => (props.hasMarginLeft ? '40px' : ''),
    [theme.breakpoints.down('sm')]: {
      marginLeft: (props) => (props.hasMarginLeft ? '30px' : ''),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: (props) => (props.hasMarginLeft ? '20px' : ''),
    },
  },
  iconWrapper: {
    width: '36px',
    height: '36px',
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '30px',
      marginRight: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '28px',
      height: '28px',
      marginRight: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '26px',
      height: '26px',
      marginRight: '8px',
    },
  },
  label: {
    fontFamily: '"DM Sans", sans-serif',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    lineHeight: '2.6rem',
    letterSpacing: '0.025rem',
    color: '#666666',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
      lineHeight: '2.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.2rem',
      lineHeight: '3.2rem',
    },
  },
}));

export default useStyles;
