import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import ProjectChallengeListItem from './ProjectChallengeListItem';

const ProjectChallengesSection = ({ challenges, gradientColorLight, gradientColorDark }) => (
  <section>
    {challenges.map((challenge, index) => (
      <ProjectChallengeListItem
        key={challenge.id}
        heading={challenge.title}
        text={challenge.description}
        image={challenge.fluid}
        gradientColorLight={gradientColorLight}
        gradientColorDark={gradientColorDark}
        flipped={index % 2 === 1}
      />
    ))}
  </section>
);

ProjectChallengesSection.defaultProps = {
  challenges: [],
};

ProjectChallengesSection.propTypes = {
  challenges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fluid: GatsbyImagePropTypes.fluid.isRequired,
  })),
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
};

export default ProjectChallengesSection;
