import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useBreakpoints from '@utils/useBreakpoints';
import SocialMediaIconContainer from '@components/common/SocialMediaIconContainer';
import useStyles from './styles';

const ShareThisPage = ({ label }) => {
  const classes = useStyles();
  const { smDown, mdDown } = useBreakpoints();

  return (
    <div className={classes.root}>
      <hr className={classes.border} />
      <div className={classes.wrapper}>
        <div className={classes.labelContainer}>
          <Typography variant="body2" className={classes.label}>
            { label }
          </Typography>
          <SocialMediaIconContainer
            facebook
            twitter
            iconWidth={smDown ? 30 : mdDown ? 40 : 50}
            gap={smDown ? 10 : mdDown ? 15 : 20}
          />
        </div>
      </div>
    </div>
  );
};

ShareThisPage.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ShareThisPage;
