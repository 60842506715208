import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import EmailIcon from '@images/email-icon.inline.svg';
import PdfIcon from '@images/pdf-icon.inline.svg';
import IconTextLink from '@components/common/IconTextLink';
import useStyles from './styles';

const ProjectIntroSection = ({ headline, body, pdfUrl, contact }) => {
  const classes = useStyles();

  return (
    <CenteredSectionContainer>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.lead}>
          <Typography variant="h3">
            { headline }
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Typography variant="body2" className={classes.text}>
            { body }
          </Typography>
          {contact ? (
            <div className={classes.actionLinkContainer}>
              <IconTextLink label="Contact Us" to="/contact">
                <EmailIcon />
              </IconTextLink>
              {pdfUrl ? (
                <IconTextLink label="Spec Sheet" href={pdfUrl} hasMarginLeft>
                  <PdfIcon />
                </IconTextLink>
              ) : null}
            </div>
          ) : null}
        </Grid>
      </Grid>
    </CenteredSectionContainer>
  );
};

ProjectIntroSection.defaultProps = {
  pdfUrl: '',
  contact: false,
};

ProjectIntroSection.propTypes = {
  headline: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string,
  contact: PropTypes.bool,
};

export default ProjectIntroSection;
