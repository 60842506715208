import { useState, useEffect } from 'react';

function getWindowDimensions() {
  let width = 0;
  let height = 0;
  let bodyWidth = 0;
  let bodyHeight = 0;
  let devicePixelRatio = 1;
  if (typeof window !== 'undefined') {
    const { innerWidth, innerHeight, document, devicePixelRatio: dpr } = window; // eslint-disable-line
    width = innerWidth;
    height = innerHeight;
    bodyWidth = document.body.clientWidth;
    bodyHeight = document.body.clientHeight;
    devicePixelRatio = dpr;
  }
  return { width, height, bodyWidth, bodyHeight, devicePixelRatio };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);  // eslint-disable-line
    return () => window.removeEventListener('resize', handleResize);  // eslint-disable-line
  }, []);

  return windowDimensions;
}
