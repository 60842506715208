import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: (props) => (props.morePadding ? '350px 0 174px' : '260px 0 174px'),
    [theme.breakpoints.down('lg')]: {
      padding: (props) => (props.morePadding ? '280px 0 174px' : '220px 0 174px'),
    },
    [theme.breakpoints.down('md')]: {
      padding: (props) => (props.morePadding ? '280px 0 150px' : '220px 0 150px'),
    },
    [theme.breakpoints.down('sm')]: {
      padding: (props) => (props.morePadding ? '180px 0 76px' : '130px 0 76px'),
    },
    [theme.breakpoints.down('xs')]: {
      padding: (props) => (props.morePadding ? '150px 0 85px' : '90px 0 85px'),
    },
  },
}));

export default useStyles;
