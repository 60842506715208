import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '50px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 112px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 25px',
    },
  },
  border: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    borderWidth: '0.5px',
    borderColor: '#e2e2e2',
    margin: '0',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '1000px',
  },
  label: {
    marginRight: '30px',
    [theme.breakpoints.down('md')]: {
      marginRight: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '15px',
    },
  },
  labelContainer: {
    position: 'absolute',
    right: '0',
    backgroundColor: 'white',
    height: '50px',
    paddingLeft: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
