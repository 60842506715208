import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { openImageGalleryModal, closeImageGalleryModal } from '@store/actions';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import Carousel, { Modal, ModalGateway } from 'react-images';
import SrcSet from 'srcset';
import useWindowDimensions from '@utils/useWindowDimensions';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import ProjectImageGalleryListItem from './ProjectImageGalleryListItem';

const FooterCaption = ({ innerProps, currentView }) => (
  <Typography
    variant="body2"
    color="textSecondary"
    align="center"
    style={{ width: '100%' }}
    {...innerProps} // eslint-disable-line
  >
    { currentView.caption }
  </Typography>
);

FooterCaption.propTypes = {
  innerProps: PropTypes.object.isRequired, // eslint-disable-line
  currentView: PropTypes.object.isRequired, // eslint-disable-line
};

const ProjectImageGallerySection = ({ images }) => {
  const isModalOpen = useSelector((state) => state.isImageGalleryModalOpen);
  const dispatch = useDispatch();
  const openModal = () => dispatch(openImageGalleryModal());
  const closeModal = () => dispatch(closeImageGalleryModal());

  // const [isModalOpen, setModalState] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  // const closeModal = () => setModalState(false);
  // const openModal = () => setModalState(true);
  const handleClick = (index) => {
    openModal();
    setImageIndex(index);
  };

  // dynamically select the right image src value from the srcset of each gatsby image fluid,
  // depending on the current window width
  const { width: windowWidth, devicePixelRatio } = useWindowDimensions();
  const imagesWithParsedSrcSet = images.map((image) => ({
    srcset: SrcSet.parse(image.image.fluid.srcSet),
    caption: image.caption,
  }));
  // console.log('imagesWithParsedSrcSet', imagesWithParsedSrcSet); // eslint-disable-line
  const imagesWithSortedSrcSet = imagesWithParsedSrcSet.map((image) => ({
    // sort by src's width value
    srcset: image.srcset.sort((srcA, srcB) => {
      if (!srcA.width || !srcB.width) return 0;
      if (srcA.width - srcB.width > 0) return 1;
      if (srcA.width - srcB.width < 0) return -1;
      return 0;
    }),
    caption: image.caption,
  }));
  // console.log('imagesWithSortedSrcSet', imagesWithSortedSrcSet); // eslint-disable-line
  const getRightImageSrc = (srcset) => {
    const actualWidth = windowWidth * devicePixelRatio;
    let closestSizeSrc = srcset[srcset.length - 1]; // default to the largest image src
    if (closestSizeSrc.width) {
      for (let i = srcset.length - 1; i >= 0; i--) {
        const src = srcset[i];
        if (src.width && actualWidth < src.width) {
          closestSizeSrc = src;
        }
      }
    }
    return closestSizeSrc.url;
  };
  const carouselImages = () => imagesWithSortedSrcSet.map((image) => ({
    source: getRightImageSrc(image.srcset),
    caption: image.caption,
  }));

  return (
    <>
      <CenteredSectionContainer paddingSm="0">
        <Grid container>
          {images.map((image, index) => (
            <ProjectImageGalleryListItem
              key={image.id}
              thumbnail={image.thumbnail.fluid}
              imageAlt={image.thumbnail.title}
              caption={image.caption}
              onClick={() => handleClick(index)}
            />
          ))}
        </Grid>
      </CenteredSectionContainer>
      <ModalGateway>
        {isModalOpen ? (
          <Modal onClose={closeModal}>
            <Carousel
              components={{ FooterCaption }}
              currentIndex={imageIndex}
              views={carouselImages()}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

ProjectImageGallerySection.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    caption: PropTypes.string,
    image: PropTypes.shape({
      title: PropTypes.string,
      fluid: GatsbyImagePropTypes.fluid.isRequired,
    }),
    thumbnail: PropTypes.shape({
      title: PropTypes.string,
      fluid: GatsbyImagePropTypes.fluid.isRequired,
    }),
  })).isRequired,
};

export default ProjectImageGallerySection;
