import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  lead: {
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
      paddingBottom: '30px',
    },
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
  actionLinkContainer: {
    display: 'flex',
    marginTop: '30px',
  },
}));

export default useStyles;
