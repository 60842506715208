import React from 'react';
import PropTypes from 'prop-types';
import AmbiguousLink from '@components/common/AmbiguousLink';
import useStyles from './styles';

const IconTextLink = ({
  children: Icon,
  label,
  to,
  href,
  hasMarginLeft,
}) => {
  const classes = useStyles({ hasMarginLeft });
  return (
    <AmbiguousLink to={to} href={href}>
      <div className={classes.container}>
        <div className={classes.iconWrapper}>
          { Icon }
        </div>
        <div className={classes.label}>
          { label }
        </div>
      </div>
    </AmbiguousLink>
  );
};

IconTextLink.defaultProps = {
  to: '',
  href: '',
  hasMarginLeft: false,
};

IconTextLink.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  hasMarginLeft: PropTypes.bool,
};

export default IconTextLink;
