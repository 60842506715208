import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const ProjectHeaderSubtitle = ({ date, subtitle }) => {
  const dateString = () => {
    const d = new Date(date);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  return (
    <Typography variant="h5" color="textSecondary">
      {date ? (
        <span>
          { dateString() }
          ,&nbsp;
        </span>
      ) : null}
      { subtitle }
    </Typography>
  );
};

ProjectHeaderSubtitle.defaultProps = {
  date: '',
  subtitle: '',
};

ProjectHeaderSubtitle.propTypes = {
  date: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ProjectHeaderSubtitle;
