import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import ProjectHeader from '@components/project-detail/ProjectHeader';
import ProjectPrimaryImage from '@components/project-detail/ProjectPrimaryImage';
import ProjectIntroSection from '@components/project-detail/ProjectIntroSection';
import ProjectPrimaryVideoSection from '@components/project-detail/ProjectPrimaryVideoSection';
import ProjectImageGallerySection from '@components/project-detail/ProjectImageGallerySection';
import ProjectChallengesSection from '@components/project-detail/ProjectChallengesSection';
import ShareThisPage from '@components/common/ShareThisPage';
import FeaturedProjectsSection from '@components/common/FeaturedProjectsSection';
import ContactBannerSection from '@components/common/ContactBannerSection';
import SectionSpacer from '@components/common/SectionSpacer';

const ProductDetailPage = ({ pageContext }) => {
  const { content } = pageContext;

  return (
    <Layout>
      <SEO title={content.title} />
      <ProjectHeader
        morePadding
        title={content.title}
        date={content.publishDate}
        subtitle={content.subtitle}
        backgroundImage={content.headerBackgroundImage.fluid}
      />
      <ProjectPrimaryImage
        image={content.primaryImage.fluid}
        gradientColorLight={content.gradientColorLight}
        gradientColorDark={content.gradientColorDark}
      />
      <SectionSpacer xs="30px" />
      <ProjectIntroSection
        headline={content.introHeading}
        body={content.introBody.introBody}
        pdfUrl={content.pdf ? content.pdf.file.url : ''}
        contact
      />
      <SectionSpacer />
      {content.primaryVideo
        ? (
          <>
            <ProjectPrimaryVideoSection
              videoId={content.primaryVideo}
            />
            <SectionSpacer />
          </>
        ) : null}
      <ProjectImageGallerySection
        images={content.imageGallery}
      />
      <SectionSpacer />
      {content.productFeatures
        ? (
          <>
            <ProjectChallengesSection
              challenges={content.productFeatures}
              gradientColorLight={content.gradientColorLight}
              gradientColorDark={content.gradientColorDark}
            />
            <SectionSpacer sm="0" xs="0" />
          </>
        ) : null}
      <ShareThisPage label="Share Project" />
      <SectionSpacer />
      <FeaturedProjectsSection />
      <ContactBannerSection />
    </Layout>
  );
};

ProductDetailPage.propTypes = {
  pageContext: PropTypes.object.isRequired, // eslint-disable-line
};

export default ProductDetailPage;
