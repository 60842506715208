import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textBlock: {
    width: '50%',
    background: (props) => `linear-gradient(135deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 100px',
    [theme.breakpoints.down('md')]: {
      padding: '0 50px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 60px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 25px 40px',
    },
  },
  text: {
    marginTop: '40px',
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
}));

export default useStyles;
