import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import GatsbyBackgroundImage from 'gatsby-background-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const ProjectImageGalleryListItem = ({ thumbnail, imageAlt, onClick }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={4}
      sm={3}
      className={classes.root}
      onClick={onClick}
    >
      <GatsbyBackgroundImage
        fluid={thumbnail}
        alt={imageAlt}
        className={classes.thumbnail}
      />
    </Grid>
  );
};

ProjectImageGalleryListItem.propTypes = {
  thumbnail: GatsbyImagePropTypes.fluid.isRequired,
  imageAlt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProjectImageGalleryListItem;
