import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    border: 'solid 1px white',
    position: 'relative',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  thumbnail: {
    position: 'absolute',
    height: '100%',
  },
}));

export default useStyles;
