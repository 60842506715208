import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imageBlock: {
    width: '50%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 25px',
    },
  },
  image: {
    paddingTop: '75%',
  },
}));

export default useStyles;
