import React from 'react';
import GatsbyBackgroundImage from 'gatsby-background-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const ProjectChallengeListItemImage = ({ image }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageBlock}>
      <GatsbyBackgroundImage
        fluid={image}
        className={classes.image}
      />
    </div>
  );
};

ProjectChallengeListItemImage.propTypes = {
  image: GatsbyImagePropTypes.fluid.isRequired,
};

export default ProjectChallengeListItemImage;
