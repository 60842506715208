import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import GatsbyBackgroundImage from 'gatsby-background-image';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import ProjectHeaderSubtitle from './ProjectHeaderSubtitle';
import useStyles from './styles';

const ProjectHeader = ({ title, date, subtitle, backgroundImage, morePadding }) => {
  const classes = useStyles({ morePadding });

  return (
    <GatsbyBackgroundImage
      Tag="header"
      fluid={backgroundImage}
      className={classes.root}
    >
      <CenteredSectionContainer>
        <Typography variant="h2" color="textSecondary">
          { title }
        </Typography>
        {subtitle ? (
          <ProjectHeaderSubtitle
            date={date}
            subtitle={subtitle}
          />
        ) : null}
      </CenteredSectionContainer>
    </GatsbyBackgroundImage>
  );
};

ProjectHeader.defaultProps = {
  date: '',
  subtitle: '',
  morePadding: false,
};

ProjectHeader.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImage: GatsbyImagePropTypes.fluid.isRequired,
  morePadding: PropTypes.bool,
};

export default ProjectHeader;
