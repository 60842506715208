import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    paddingLeft: 'calc((100% - 1000px) / 2)',
    [theme.breakpoints.down('md')]: {
      marginBottom: '8px',
      paddingLeft: 'calc((100% - 800px) / 2)',
    },
    '@media (max-width: 860px)': {
      paddingLeft: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '25px',
    },
  },
  wrapper: (props) => ({
    width: '100%',
    position: 'relative',
    marginTop: '-124px',
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: '100',
      width: '100%',
      height: '10px',
      background: `linear-gradient(90deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`,
      [theme.breakpoints.down('md')]: {
        height: '8px',
      },
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '-110px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-46px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-48px',
    },
  }),
  image: {
    paddingTop: '56.25%',
  },
}));

export default useStyles;
