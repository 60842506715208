import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useWindowDimensions from '@utils/useWindowDimensions';
import useBreakpoints from '@utils/useBreakpoints';
import useStyles from './styles';

const ProjectChallengeListItemText = ({
  heading,
  text,
  gradientColorLight,
  gradientColorDark,
}) => {
  const classes = useStyles({ gradientColorLight, gradientColorDark });
  const { width: windowWidth } = useWindowDimensions();
  const { smDown, xsDown } = useBreakpoints();

  const textBlockStyle = () => {
    let imageHorizontalPadding;
    const imageRatio = 3 / 4;
    const textBlockPaddingTop = 40;
    if (smDown) {
      imageHorizontalPadding = 30 * 2;
    } else if (xsDown) {
      imageHorizontalPadding = 40 * 2;
    }

    const imageHeight = (windowWidth - imageHorizontalPadding) * (imageRatio / 2);

    return {
      paddingTop: (windowWidth) ? `${imageHeight + textBlockPaddingTop}px` : `${textBlockPaddingTop}px`,
      marginTop: (windowWidth) ? `${-imageHeight}px` : '0',
    };
  };

  return (
    <div
      className={classes.textBlock}
      style={(smDown || xsDown) ? textBlockStyle() : null}
    >
      <div className={classes.textBlockInner}>
        <Typography variant="h3" color="textSecondary">
          { heading }
        </Typography>
        <Typography variant="body2" className={classes.text} color="textSecondary">
          { text }
        </Typography>
      </div>
    </div>
  );
};

ProjectChallengeListItemText.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
};

export default ProjectChallengeListItemText;
