import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  playbackIcon: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    width: '100px',
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '90px',
      height: '90px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      height: '80px',
    },
  },
}));

export default useStyles;
