import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: (props) => (props.flipped ? 'row-reverse' : 'row'),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: '80px',
    },
  },
}));

export default useStyles;
