import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import YouTube from 'react-youtube';
import PlaybackIcon from '@images/playback.inline.svg';
import useStyles from './styles';

const ProjectPrimaryVideoSection = ({ videoId }) => {
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const classes = useStyles();
  const options = {
    width: '100%',
    height: '100%',
    playerVars: {
      modestbranding: 1,
      rel: 0,
      enablejsapi: 1,
    },
  };
  const onReady = (event) => {
    setPlayer(event.target);
  };
  const onPlay = () => {
    setIsPlaying(true);
  };
  const onPause = () => {
    setIsPlaying(false);
  };
  const playVideo = () => {
    if (player) {
      player.playVideo();
      setIsPlaying(true);
    }
  };

  return (
    <CenteredSectionContainer>
      <div className={classes.videoContainer}>
        <YouTube
          className={classes.video}
          videoId={videoId}
          opts={options}
          onReady={onReady}
          onPlay={onPlay}
          onPause={onPause}
        />
        <Fade in={!isPlaying}>
          <PlaybackIcon
            className={classes.playbackIcon}
            onClick={playVideo}
          />
        </Fade>
      </div>
    </CenteredSectionContainer>
  );
};

ProjectPrimaryVideoSection.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default ProjectPrimaryVideoSection;
