import React from 'react';
import PropTypes from 'prop-types';
import GatsbyBackgroundImage from 'gatsby-background-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const ProjectHeadingImage = ({ image, gradientColorLight, gradientColorDark }) => {
  const classes = useStyles({ gradientColorLight, gradientColorDark });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <GatsbyBackgroundImage
          alt="heading-image"
          fluid={image}
          className={classes.image}
        />
      </div>
    </div>
  );
};

ProjectHeadingImage.propTypes = {
  image: GatsbyImagePropTypes.fluid.isRequired,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
};

export default ProjectHeadingImage;
