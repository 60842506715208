import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import ProjectChallengeListItemImage from './ProjectChallengeListItemImage';
import ProjectChallengeListItemText from './ProjectChallengeListItemText';
import useStyles from './styles';

const ProjectChallengeListItem = ({
  heading,
  text,
  image,
  gradientColorLight,
  gradientColorDark,
  flipped,
}) => {
  const classes = useStyles({ flipped });

  return (
    <div className={classes.root}>
      <ProjectChallengeListItemImage
        image={image}
      />
      <ProjectChallengeListItemText
        heading={heading}
        text={text}
        gradientColorLight={gradientColorLight}
        gradientColorDark={gradientColorDark}
        flipped={flipped}
      />
    </div>
  );
};

ProjectChallengeListItem.defaultProps = {
  flipped: false,
};

ProjectChallengeListItem.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
  flipped: PropTypes.bool,
};

export default ProjectChallengeListItem;
